<template>
  <div class="article-details">
    <div class="begin pb-16">
      <div class="back">
        <svg-icon
          name="ic_go-back"
          original
          class="w-8 h-8 cursor-pointer"
          @click="goBack"
        />
      </div><br>
      <div class="body bg-white rounded mt-4 p-8">
        <div class="details-begin flex">
          <div class="part-1 w-11/12 mr-4">
            <div class="title font-medium text-2xl text-black">
              {{ articleDetails.title }}
            </div><br>
            <div class="picture rounded-lg mt-4">
              <div
                class="image w-full h-64 bg-no-repeat bg-cover bg-center rounded-lg"
                v-bind:style="{ 'background-image': 'url(' + file + ')' }"
              ></div>
            </div><br>
            <div class="description text-black font-medium text-lg leading-relaxed">
              {{ articleDetails.description }}
            </div><br>
            <div class="blog-content mt-4">
              <div
                v-for="(item, i) in articleDetails.article"
                :key="i"
                class="content"
              >
                <div class="content-bloc leading-relaxed text-justify" v-html="item.content"></div>
              </div>
            </div>
          </div>
          <div class="part-2 w-1/12 ml-4 invisible">
            <div class="icons flex items-center">
              <div class="update mr-3 cursor-pointer">
                <svg-icon name="ic_update_article" original class="w-8 h-8" />
              </div>
              <div class="delete ml-3 cursor-pointer" @click="openDeleteConfirmation">
                <svg-icon name="ic_delete_article" original class="w-8 h-8" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CONFIRM DELETE ARTICLE POPUP -->
    <delete-confirmation v-if="isConfirmation" @closeDelete="closeDelete" />
  </div>
</template>

<script>
import http from '@/plugins/http'
import apiRoute from '@/router/api-routes'
import DeleteConfirmation from '@/components/helper/article/delete-confirmation'
export default {
  name: 'article-details',
  components: { DeleteConfirmation },
  props: {
    articleDetails: Object
  },
  data () {
    return {
      file: '',
      isConfirmation: false
    }
  },
  created () {
    console.log(this.articleDetails)
    this.getArticleFile()
  },
  methods: {
    closeDelete (value) {
      this.isConfirmation = value
    },
    openDeleteConfirmation () { // OPEN CONFIRMATION DELETION POPUP
      this.isConfirmation = true
    },
    getArticleFile () { // GET ARTICLE PICTURE
      http.get(apiRoute.baseURL + apiRoute.getFileById + this.articleDetails.pictureId)
        .then(response => {
          // console.log(response)
          this.file = response.file
        }).catch(error => {
          console.log(error)
        })
    },
    goBack () { // GO BACK TO ARTICLE LIST PAGE
      this.$emit('closeArticleDetails', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .image {
    height: 35rem;
  }
</style>
