<template>
  <div class="article-by-category">
    <div v-if="!addNewArticle && !oneArticleDetails" class="begin">
      <div class="header">
        <div class="back">
          <svg-icon
            name="ic_go-back"
            original
            class="w-8 h-8 cursor-pointer"
            @click="goBack"
          />
        </div><br>
        <div class="flex items-center justify-between">
          <div class="title flex text-lg">
            <div class="page-name mr-2">{{ $t('articleMenu') }}</div>
            <div class="gender text-black font-medium">{{ '/ ' + ' ' + isCategoryInfo.label }}</div>
          </div>
          <div class="flex-one current-month ">
            <div class="search-bloc flex items-center justify-between">
              <div class="select-pays">
                <button-base
                  :label="$t('addArticleButton')"
                  @click.native="showAddArticle"
                />
              </div>
            </div>
          </div>
        </div><br>
        <div class="body mt-4">
          <div class="loading" :class="{ 'not_empty': isLoading === false }">
            <loading
              :active="isLoading"
              :can-cancel="true"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              loader="dots"
              color="#0B5382"
              background-color="transparent"
            />
          </div>
          <div v-if="nothing && !isLoading" class="nothing text-center py-8 text-xl">
            {{ $t('noCategoryArticleAdded') }}
          </div>
          <div v-if="!isLoading" class="listing flex flex-wrap">
            <article-list
              v-for="(item, i) in articles"
              :key="i"
              :article-list="item"
              class="w-1/3 pb-12 mr-6 cursor-pointer"
              @click.native="openArticleDetails(i)"
              @openConfirmation="openConfirmation"
              @openUpdateArticle="openUpdateArticle"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- ADD NEW ARTICLE PAGE -->
    <add-article
      v-if="addNewArticle"
      :category-information="isCategoryInfo"
      :article-data="articleId"
      @closeAddArticle="closeAddArticle"
      @closeSuccess="closeSuccess"
    />
    <!-- ARTICLE DETAILS PAGE -->
    <article-details
      v-if="oneArticleDetails"
      :article-details="isArticleDetails"
      @closeArticleDetails="closeArticleDetails"
    />

    <success-add v-if="isSuccess" message="Article supprimé avec succès" button-label="Retour" @closeSuccess="closeSuccess"></success-add>

    <delete-confirmation v-if="isDelete" :article-id="articleId" @openSuccessDelete="openSuccessDelete" @closeDelete="closeDelete"/>
  </div>
</template>

<script>
import ArticleList from '@/components/helper/article/article-list'
import ButtonBase from '@/components/helper/add/button-base'
import http from '@/plugins/http'
import apiRoute from '@/router/api-routes'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import AddArticle from '@/components/helper/article/add-article'
import ArticleDetails from '@/components/helper/article/article-details'
import DeleteConfirmation from '@/components/helper/article/delete-confirmation.vue'
import SuccessAdd from '@/components/helper/success-add.vue'
export default {
  name: 'article-by-category',
  components: { SuccessAdd, DeleteConfirmation, ArticleDetails, AddArticle, ButtonBase, ArticleList, Loading },
  props: {
    isCategoryInfo: Object
  },
  data () {
    return {
      articles: [],
      isLoading: true,
      fullPage: false,
      nothing: false,
      addNewArticle: false,
      oneArticleDetails: false,
      isArticleDetails: {},
      isDelete: false,
      articleId: null,
      isSuccess: false
    }
  },
  created () {
    this.getAllArticle()
  },
  methods: {
    openUpdateArticle (value) {
      this.articleId = value
      this.addNewArticle = true
    },
    openConfirmation (value) {
      this.articleId = value
      console.log(this.articleId)
      this.isDelete = true
    },
    openSuccessDelete (value) {
      this.isSuccess = value
      this.isDelete = false
    },
    closeDelete (value) {
      this.isDelete = value
    },
    closeSuccess (value) {
      this.addNewArticle = value
      this.isSuccess = value
      this.articleId = null
      this.getAllArticle()
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    closeArticleDetails (value) { // CLOSE ARTICLE DETAILS
      this.oneArticleDetails = value
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    openArticleDetails (index) { // OPEN ARTICLE DETAILS
      this.oneArticleDetails = true
      this.isArticleDetails = this.articles[index]
    },
    getAllArticle () { // GET ALL ARTICLES BY CATEGORY
      http.post(apiRoute.baseURL + apiRoute.getArticleByCategory, {
        categorieId: this.isCategoryInfo.id
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        console.log(response)
        this.articles = response
        if (this.articles.length === 0) {
          this.isLoading = false
          this.nothing = true
        } else {
          this.isLoading = false
          this.nothing = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    closeAddArticle (value) { // CLOSE ADD NEW ARTICLE PAGE
      this.addNewArticle = value
      this.isLoading = true
      this.articleId = null
      this.getAllArticle()
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    showAddArticle () { // OPEN ADD ARTICLE PAGE
      this.addNewArticle = true
    },
    goBack () { // GO BACK TO ARTICLE INDEX PAGE
      this.$emit('closeArticleCategory', false)
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
  .article-list {
    width: 31%;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
</style>
