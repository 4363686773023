<template>
  <div class="article">
    <div v-if="!isArticleByCategory" class="begin">
      <div class="header">
        <div class="flex items-center justify-between">
          <div class="title flex text-lg">
            <div class="page-name mr-2 text-black">{{ $t('articleMenu') }}</div>
          </div>
          <div class="flex-one current-month ">
            <div class="search-bloc flex items-center justify-between">
              <div class="select-pays">
                <button-base
                  :label="$t('addCategoryButton')"
                  @click.native="openAddCategory"
                />
              </div>
            </div>
          </div>
        </div><br>
        <div class="body bg-white mt-4 p-8 rounded">
          <div class="loading" :class="{ 'not_empty': isLoading === false }">
            <loading
              :active="isLoading"
              :can-cancel="true"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              loader="dots"
              color="#0B5382"
              background-color="transparent"
            />
          </div>
          <div v-if="nothing && !isLoading" class="nothing text-center py-8 text-xl">
            {{ $t('noArticleCategoryAdded') }}
          </div>
          <div v-if="!isLoading" class="categories">
            <div class="categories-lists flex items-center justify-between flex-wrap w-full">
              <category-list
                v-for="(item, i) in allCategory"
                :key="i"
                :categories-lists="item"
                class="w-5/12 mr-8"
                @click.native="showArticleByCategory(i)"
                @openConfirmationCategory="openConfirmationCategory"
                @openUpdateCategory="openUpdateCategory"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ADD ARTICLE CATEGORY POPUP -->
    <add-category
      v-if="addCategory"
      :category-data="categoryId"
      @closeAddCategory="closeAddCategory"
      @openSuccessAdd="openSuccessAdd"
    />
    <!-- SUCCESS POPUP -->
    <success-add
      v-if="isSuccess"
      :message="message"
      :button-label="buttonLabel"
      @closeSuccess="closeSuccess"
    />
    <!-- ARTICLE BY CATEGORY LIST PAGE -->
    <article-by-category
      v-if="isArticleByCategory"
      :is-category-info="categoryInfo"
      @closeArticleCategory="closeArticleCategory"
    />

    <confirm-delete-categ
      v-if="isDeletion"
      :category-id="categoryId"
      @closeDeleteCategory="closeDeleteCategory"
      @openSuccessDelete="openSuccessDelete"
    />
  </div>
</template>

<script>
import ButtonBase from '../../components/helper/add/button-base'
import http from '../../plugins/http'
import apiRoute from '../../router/api-routes'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import AddCategory from '@/components/helper/article/add-category'
import SuccessAdd from '@/components/helper/success-add'
import CategoryList from '@/components/helper/article/category-list'
import ArticleByCategory from '@/components/helper/article/article-by-category'
import ConfirmDeleteCateg from '@/components/helper/article/confirm-delete-categ.vue'
export default {
  name: 'index',
  components: { ConfirmDeleteCateg, ArticleByCategory, CategoryList, SuccessAdd, AddCategory, ButtonBase, Loading },
  data () {
    return {
      isLoading: true,
      fullPage: false,
      addCategory: false,
      isSuccess: false,
      allCategory: [],
      nothing: false,
      isArticleByCategory: false,
      categoryInfo: {},
      isDeletion: false,
      categoryId: null,
      message: '',
      buttonLabel: ''
    }
  },
  created () {
    this.getAllCategory()
  },
  methods: {
    openUpdateCategory (value) {
      this.categoryId = value
      this.addCategory = true
    },
    openSuccessDelete (value) {
      this.isDeletion = false
      this.isSuccess = value
      this.message = this.$t('categoryDeletionSuccess')
      this.buttonLabel = this.$t('goBackAddMemberButton')
    },
    openConfirmationCategory (value) {
      this.isDeletion = true
      this.categoryId = value
    },
    closeDeleteCategory (value) {
      this.isDeletion = value
    },
    closeArticleCategory (val) { // CLOSE ONE CATEGORY ARTICLES PAGE
      this.isArticleByCategory = val
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    showArticleByCategory (index) { // OPEN ONE CATEGORY ARTICLES PAGE
      this.isArticleByCategory = true
      this.categoryInfo = this.allCategory[index]
    },
    getAllCategory () { // GET BLOG CATEGORIES
      http.post(apiRoute.baseURL + apiRoute.getCategoryByType, {
        type: 'BLOG'
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.allCategory = response
        if (this.allCategory.length === 0) {
          this.isLoading = false
          this.nothing = true
        } else {
          this.isLoading = false
          this.nothing = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    closeSuccess (val) { // CLOSE SUCCESS POPUP
      this.isSuccess = val
      this.isLoading = true
      this.getAllCategory()
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    openSuccessAdd (val) { // OPEN SUCCESS POPUP
      this.isSuccess = val
      this.addCategory = false
      this.message = this.$t('newCategorySuccess')
      this.buttonLabel = this.$t('goBackAddMemberButton')
    },
    closeAddCategory () { // CLOSE ADD ARTICLE CATEGORY POPUP
      this.addCategory = false
    },
    openAddCategory () { // OPEN ADD ARTICLE CATEGORY POPUP
      this.addCategory = true
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .article {
    padding: 2rem 4rem 4rem 1.5rem;
  }
  .body {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    min-height: 70vh;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
  .category-list {
    width: 48%;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  .article-list {
    width: 31%;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
</style>
