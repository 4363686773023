<template>
  <div class="delete-confirmation">
    <popup-base>
      <div class="begin bg-white m-auto w-1/2 rounded-lg p-8">
        <div class="header flex items-center justify-between">
          <div class="header-title font-medium text-2xl text-black">{{ $t('articleSuppression') }}</div>
          <div class="delete-icon cursor-pointer" @click="goBack">
            <svg-icon name="ic_close" original class="w-4 h-4"/>
          </div>
        </div><br>
        <div class="body mt-6">
          <div class="title">{{ $t('sureDelete') }}</div><br>
          <div class="delete-button flex items-center justify-end mt-4">
            <div class="deletion">
              <button-base
                :label="$t('deleteButton')"
                :is-loading="loader"
                @click.native="deleteArticle"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '@/components/helper/add/popup-base'
import ButtonBase from '@/components/helper/add/button-base'
import http from '@/plugins/http'
import apiRoute from '@/router/api-routes'
export default {
  name: 'delete-confirmation',
  components: { ButtonBase, PopupBase },
  props: {
    articleId: Number
  },
  data () {
    return {
      loader: false
    }
  },
  methods: {
    deleteArticle () { // DELETE ONE ARTICLE
      this.loader = true
      http.delete(apiRoute.baseURL + apiRoute.deleteArticle + this.articleId)
        .then(response => {
          console.log(response)
          this.$emit('openSuccessDelete', true)
        }).catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.$emit('closeDelete', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .deletion::v-deep {
    .button-base {
      .page-button-real {
        background-color: #C43A2B !important;
      }
    }
  }
</style>
